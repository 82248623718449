<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="用户昵称：">
        <el-input size="small" v-model="searchForm.nickname" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader
          v-model="searchForm.area_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="推广员信息" width="220" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="推广员姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="推广员电话" align="center"></el-table-column>
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="total_order_amount" label="累计推广订单金额" align="center">
        <template v-slot="{ row }">￥{{ row.total_order_amount }}</template>
      </el-table-column>
      <el-table-column prop="total_order_count" label="累计推广订单数" align="center"></el-table-column>
      <el-table-column prop="total_amount" label="累计推广奖励" align="center">
        <template v-slot="{ row }">￥{{ row.total_amount }}</template>
      </el-table-column>
      <el-table-column prop="total_user_count" label="累计用户数" align="center"></el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="$router.push({ path: '/extension/promoterDetail', query: { id: scope.row.id, info: JSON.stringify(scope.row) } })" type="text" size="small">详情</el-button>
          <el-popover style="margin-left: 10px" width="180" height="150px" placement="left" trigger="click">
            <el-image id="wxcode" @click="showwxcode" :src="scope.row.qrcode"></el-image>
            <el-button slot="reference" @click="showCode(scope.row)" type="text" size="small">微信码</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script></script>
<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import userInfo from '@/components/userInfo';
export default {
  components: {
    Paging,
    userInfo,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        province_id: '',
        city_id: '',
        district_id: '',
        start_time: '',
        area_id: [],
        end_time: '',
      },
      total_number: 0,
      list: [],
      areaList: [],
    };
  },
  created() {
    if (this.$route.query.cityId) {
      this.searchForm.area_id = JSON.parse(this.$route.query.cityId);
    }
    this.getList();
    this.getArea();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    search() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        province_id: '',
        city_id: '',
        district_id: '',
        start_time: '',
        end_time: '',
        area_id: [],
      };
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      searchForm.province_id = searchForm.area_id[0];
      searchForm.city_id = searchForm.area_id[1];
      searchForm.district_id = searchForm.area_id[2];
      delete searchForm.area_id;
      this.$axios.post(this.$api.O2O.distributionList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.qrcode = '';
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    showCode(row) {
      if (!row.qrcode) {
        this.$axios
          .post(this.$api.O2O.qrcode, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.qrcode = res.result;
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
